import React from 'react'
import FileIcon from '../../../../assets/svg/homepage/file.svg';
import EditIcon from "../../../../assets/svg/video-step-2.svg";
import TemplatesIcon from '../../../../assets/svg/account/squares.svg';
import * as styles from './steps.module.scss'

const Step = ({index, title, subtitle, icon: Icon}) => {
    return(
        <div className={styles.step}>
            <div className={styles.index}>
                <Icon />
            </div>
            <div className={styles.text}>
                <h4>{index}. {title}</h4>
                <p>{subtitle}</p>
            </div>
        </div>
    )
}

const CategorySteps = ({name}) => {
    return(
        <div className={styles.container}>
            <Step index={1} icon={TemplatesIcon} title={'Browse Templates'} subtitle={`Start from a blank template or use the collection of pre-made ${name} mockups below to get started.`}/>
            <Step index={2} icon={EditIcon} title={'Customize'} subtitle={'Replace screen placeholder with images & video. Tweak colours, change device angles & positioning.'}/>
            <Step index={3} icon={FileIcon} title={'Export'} subtitle={'Download your high-res mockup in PNG, MP4 & WEBM formats.'}/>
        </div>
    )
}

export default CategorySteps;