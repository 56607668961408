import React from 'react'
import Layout from "../../layout"
import Header from './Header/Header'
import Templates from './Templates/Templates'
import Subcategories from './Subcategories/Subcategories'
import SEO from '../../seo'
import * as styles from './categorytemplate.module.scss'
import Steps from './Steps/Steps'

export default ({pageContext}) => {
    let {title,display_name, seo_description, seo_title, header_img, header_alt, description, bg_color, slug, youtube_video, breadcrumbs, children} = pageContext
    return(
        <Layout>
            <SEO title={seo_title} description={seo_description}/>
            <div className={styles.container}>
                <Header breadcrumbs={breadcrumbs} title={title} youtubeUrl={youtube_video} imageSrc={header_img} imageAlt={header_alt} color={bg_color} description={description} />
                {header_img && breadcrumbs.length > 1 ? 
                <Steps name={display_name} /> : null}
                <Subcategories subcategories={children} />
                <Templates slug={slug} title={'Templates'} />
            </div>
        </Layout>
    )
}