import { Link, navigate } from "gatsby";
import React from "react";
import { useDispatch } from "react-redux";
import { resetCanvas } from "../../../../slices/canvas-slice";
import { resetCanvas as resetThreeCanvas } from "../../../../slices/three-slice";
import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import ImageComponent from "../../../ImageComponent";
import * as styles from "./header.module.scss";

export default ({
  color,
  title,
  description,
  imageSrc,
  imageAlt,
  youtubeUrl,
  breadcrumbs,
}) => {
  const BreadcrumbsTitle = () => {
    return (
      <>
        {breadcrumbs ?
        <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}
        <h1 className={styles.heading}>{title}</h1>
      </>
    );
  };

  const ActionButtons = () => {
    let dispatch = useDispatch()

    function createNew(type){
      if(type === '2d'){
        dispatch(resetCanvas())
        navigate('/editor/2D/')
      }
      else{
        dispatch(resetThreeCanvas())
        setTimeout(() => {
          window.location = '/editor/3D'
      }, 200)
      }
    }

    return(
      <div className={styles.actionButtons}>
        <button onClick={() => createNew('2d')}>2D Blank</button>
        <button onClick={() => createNew('3d')}>3D Blank</button>
        {youtubeUrl ? <a href={youtubeUrl} target={'_blank'}>
          <button>Watch Tutorial</button>
        </a> : null}
      </div>
    )
  }
  return imageSrc ? (
    <div className={styles.hero} style={{ background: `${color}` }}>
      <div className={styles.heroInner}>
        <div className={styles.content}>
          <BreadcrumbsTitle />
          <p className={styles.description}>{description}</p>
          <ActionButtons />
        </div>
        <div className={styles.headerImage}>
          <div>
            <ImageComponent src={`${imageSrc}`} alt={imageAlt || title} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <BreadcrumbsTitle />
  );
};
