import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

function renderImage(file, alt) {
  if(file){
    let image = getImage(file.node)
    return <GatsbyImage style={{minWidth: 'auto'}} image={image} alt={alt} objectFit="contain" aspectRatio objectPosition={"right bottom"} />
  }
}

const ImageComponent = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }
          ) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      `}
      render={({ images }) =>
        renderImage(
          images.edges.find(image => image.node.relativePath === props.src), props.alt
        )
      }
    />
  )
}

export default ImageComponent
