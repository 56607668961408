import React from "react";
import Groups from "../../../Common/Groups/Groups";
import * as styles from "./subcategories.module.scss";

export default ({ subcategories }) => {
  return subcategories.length ? (
    <div className={styles.browse}>
      <h4 className={styles.leafName}>Browse by category</h4>
      <Groups list={subcategories} />
    </div>
  ) : null;
};
